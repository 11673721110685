import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PrivateBusiness } from '../../../models/private-business.model';

@Component({
  selector: 'app-ownership-information',
  templateUrl: './ownership-information.component.html',
  styleUrls: ['./ownership-information.component.scss'],
})
export class OwnershipInformationComponent {
  @Input() public ownerShipInformationForm: FormGroup;
  @Input() public installationDateOptions: { label: string; value: string }[] =
    [];

  @Input() public roomTypeOptions = {};

  public roomTypeFilteredOptions: { label: string; value: string }[] = [];

  public privateBusinessOptions = [
    { label: 'Particulier', value: PrivateBusiness.PRIVATE },
    { label: 'Zakelijk', value: PrivateBusiness.BUSINESS },
  ];

  public privateBusinessChange(event: {
    originalEvent: PointerEvent;
    value: string;
  }) {
    this.roomTypeFilteredOptions = this.roomTypeOptions[event.value];
  }
}
