import { Component, Input } from '@angular/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { environment } from '@environments/environment';
import { SettingIndexedDbModel } from '@capturum/complete';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public showNavbar: boolean;

  public AppRoutes: typeof AppRoutes = AppRoutes;
  public homePageUrl = '/';
  public appName = `${environment.appName}`;
  public title = `${SettingIndexedDbModel.getValue(
    'LeadPortal.tenant_translation_key'
  )}`;
}
