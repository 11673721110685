import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserProfileService } from '@features/user/services/user-profile.service';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { AppRoutes } from '@core/enums/routes.enum';
import { User } from '../../user/models/user.model';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { Country } from '../../leads/enums/country.emum';
import { BaseDataService } from '@core/services/base-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserAgreementGuard implements CanActivate {
  constructor(
    private userService: UserProfileService<any>,
    private router: Router,
    private authService: AuthService,
    protected readonly baseDataService: BaseDataService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (
      this.authService.isInstaller() ||
      (this.isSpanishCountry && this.authService.isDistributor())
    ) {
      return this.userService.getUserProfile().pipe(
        map((user) => {
          return !!user.agreed_at;
        }),
        tap((agreedAt) => {
          if (!agreedAt && state.url !== `/${AppRoutes.userAgreement}`) {
            this.router.navigate([`/${AppRoutes.userAgreement}`]);
          }
        })
      );
    } else {
      return of(true);
    }
  }

  public isSpanishCountry(): Observable<boolean> {
    const currentUser: User = this.authService.getUser();

    return this.baseDataService
      .findByValue(BaseDataKey.COUNTRY, Country.SPAIN)
      .pipe(
        map((country) => {
          return currentUser?.country_base_data_value_id === country?.id;
        })
      );
  }
}
