import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PublicDropdownsService } from '../../services/public-dropdowns.service';
import { QuestionnaireWizardService } from '../../services/questionnaire-wizard.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-project-information-step',
  templateUrl: './project-information.component.html',
  styleUrls: ['./project-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectInformationComponent implements OnInit {
  public projectInformationForm: FormGroup;
  public currentHeatingOptions: { label: string; value: string }[] = [];
  public numberOfResidentsOptions: { label: string; value: string }[] = [];
  public preferredHeatPumpOptions: { label: string; value: string }[] = [];
  public degreeOfInsulationOptions: { label: string; value: string }[] = [];

  constructor(
    private publicDropdownsService: PublicDropdownsService,
    private _questionnaireWizardSvc: QuestionnaireWizardService
  ) {}

  public ngOnInit(): void {
    this._questionnaireWizardSvc.activeStep = 2;
    this._initProjectInformationForm();
    this.initOptions();
  }

  private _initProjectInformationForm(): void {
    this.projectInformationForm =
      this._questionnaireWizardSvc.projectInformationForm;
  }

  public back(): void {
    this._questionnaireWizardSvc.goBack('step-1', 1);
  }

  public next(): void {
    this._questionnaireWizardSvc.goTo('step-3', 3);
  }

  private initOptions(): void {
    this.currentHeatingOptions =
      this.publicDropdownsService.getCurrentHeatingOptions();
    this.numberOfResidentsOptions =
      this.publicDropdownsService.getNumberOfResidentsOptions();
    this.preferredHeatPumpOptions =
      this.publicDropdownsService.getPreferredHeatPumpOptions();
    this.degreeOfInsulationOptions =
      this.publicDropdownsService.getDegreeOfInsulationOptions();
  }
}
