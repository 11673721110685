import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  catchError,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { ProposedLeadService } from '../../services/proposed-lead.service';
import { Lead } from '../../models/lead.model';
import { ToastService } from '@capturum/ui/api';

interface StatusDetails {
  title: string;
  main: {
    name: string;
    address: string;
    city: string;
  };
  extra: {
    phone: string;
    email: string;
    room: string;
  };
}

@Component({
  selector: 'app-proposal-status-lead',
  templateUrl: './proposal-status-lead.component.html',
  styleUrls: ['./proposal-status-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalStatusLeadComponent implements OnInit {
  public tokenIsExpired = false;
  public errorMessage: string;
  public statusFields$: Observable<StatusDetails>;
  public redirectInfo: {
    redirect: boolean;
    returnUrl: string;
    toast: string;
  };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly proposedLeadApiService: ProposedLeadService,
    private readonly translateService: TranslateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.statusFields$ = this.route.params.pipe(
      first(),
      switchMap(({ token, status }) => {
        return this.proposedLeadApiService
          .getProposalStatus(token, status)
          .pipe(
            tap(({ locale }) => {
              return this.translateService.use(locale || 'en');
            }),
            tap(({ redirect, returnUrl, toast }) => {
              this.redirectInfo = {
                redirect,
                returnUrl,
                toast,
              };
            }),
            map(({ room_type, lead }) => {
              return {
                title: this.translateService.instant(
                  `LeadPortal.lead.proposal-status.${status}.title`
                ),
                main: this.getGroup(room_type, lead, status, 'main'),
                extra: this.getGroup(room_type, lead, status, 'extra'),
              };
            }),
            catchError((response) => {
              if (response.error?.data?.error) {
                this.errorMessage = response.error?.data?.error;
              } else {
                this.tokenIsExpired = true;

                if (this.redirectInfo.redirect) {
                  this.router.navigate([this.redirectInfo.returnUrl]);

                  const titleToast = this.translateService.instant(
                    'toast.warning.title'
                  );

                  this.toastService.info(titleToast, this.redirectInfo.toast, {
                    closable: true,
                    life: 50000,
                  });
                }
              }

              return EMPTY;
            })
          );
      }),
      finalize(() => {
        return this.cdr.detectChanges();
      })
    );
  }

  public getGroup(
    roomType: string,
    lead: Partial<Lead>,
    status: string,
    groupType: string
  ): any {
    if (groupType !== 'main') {
      return status !== 'reject'
        ? {
            phone: lead.phone,
            email: lead.email,
            room: roomType,
          }
        : {
            room: roomType,
          };
    } else {
      return status !== 'reject'
        ? {
            name: lead.name,
            address: lead.address,
            city: lead.city,
          }
        : {
            city: lead.city,
          };
    }
  }
}
