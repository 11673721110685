import { Inject, Injectable, Optional } from '@angular/core';
import {
  ActionOptions,
  ActionProvider,
  ListRendererFilterConfig,
} from '@capturum/builders/core';
import {
  ListRendererApiService,
  ListRendererFiltersService,
} from '@capturum/builders/list-renderer';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { PollingHandlerService } from '../../features/leads/services/polling-handler.service';
import { EXPORT_POLLING } from '@core/provider/polling.provider';

@Injectable()
export class EndpointAction implements ActionProvider {
  public fileInfo: {
    url: string;
    file_name: string;
  };

  constructor(
    private listRendererFilterService: ListRendererFiltersService,
    private listRendererApiService: ListRendererApiService,
    private apiHttp: ApiHttpService,
    private toastService: ToastService,
    private translateService: TranslateService,
    @Optional()
    @Inject(EXPORT_POLLING)
    private exportPollingService: PollingHandlerService
  ) {}

  public execute(
    options: ActionOptions,
    item: any,
    context?: Record<string, any>
  ): Observable<any> {
    if (this.exportPollingService.pollingStarted) {
      this.toastService.info(
        this.translateService.instant('LeadPortal.export.title'),
        this.translateService.instant('LeadPortal.export.in-progress.message')
      );

      return;
    }

    this.toastService.info(
      this.translateService.instant('LeadPortal.export.title'),
      this.translateService.instant('LeadPortal.export.start.message')
    );

    return this.listRendererApiService
      .getConfig(options.configurationKey, options.configurationKey)
      .pipe(
        map((configuration) => {
          const { fields, ...rest }: ListRendererFilterConfig =
            configuration.filters;
          const activeFilters = JSON.parse(
            localStorage.getItem(`${options.configurationKey}_filters`)
          );
          let listOptions: ListOptions;

          if (activeFilters) {
            const search = activeFilters?.find((activeFilter) => {
              return activeFilter.field === 'global_search';
            })?.value;
            const filters = activeFilters
              .filter((activeFilter) => {
                return activeFilter.field !== 'global_search';
              })
              .map((activeFilter) => {
                return {
                  ...activeFilter,
                  operator: activeFilter.matchMode,
                };
              });

            let sort;

            const localTableConfig = JSON.parse(
              localStorage.getItem(`state_${options.configurationKey}` || '')
            );

            if (localTableConfig && localTableConfig.sortField) {
              sort = [
                {
                  field: localTableConfig.sortField,
                  direction: localTableConfig.sortOrder === 1 ? 'asc' : 'desc',
                },
              ];
            }

            listOptions = {
              search,
              filters,
              sort: sort || configuration.source.options.sort,
            };
          }

          return `${options.action.options.url}${ApiService.generateQuery(
            listOptions
          )}`;
        }),
        switchMap((endpoint) => {
          return this.apiHttp.get(endpoint);
        }),
        tap((response) => {
          this.fileInfo = {
            url: response?.original?.url,
            file_name: response?.original?.file_name,
          };

          this.exportPollingService.startPeriodicalPolling(
            response?.original?.batch_id
          );
        })
      );
  }
}
