import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-house-type',
  templateUrl: './house-type.component.html',
  styleUrls: ['./house-type.component.scss'],
})
export class HouseTypeComponent {
  @Input() public houseTypeForm: FormGroup;
  @Input() public houseTypeOptions: {
    value: string;
    label: string;
    icon: string;
  }[] = [];

  @Output() public selectOption: EventEmitter<string> = new EventEmitter();
}
