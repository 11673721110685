import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ownership-information',
  templateUrl: './ownership-information.component.html',
  styleUrls: ['./ownership-information.component.scss'],
})
export class OwnershipInformationComponent {
  @Input() public ownerShipInformationForm: FormGroup;
  @Input() public installationDateOptions: { label: string; value: string }[] =
    [];

  @Input() public roomTypeOptions: { label: string; value: string }[] = [];
}
