<div class="questionnaire-wrapper">
  <div class="header">
    <div class="header__logo-logo2">
      <img
        src="./assets/images/logo_with_text.png"
        alt="LG Logo"
        class="logo"
      />
      <img
        class="header__logo2"
        src="assets/images/life_is_good.png"
        alt="High Five"
      />
    </div>

    <div class="progress-bar-container">
      <app-progress-bar></app-progress-bar>
    </div>
  </div>
  <div class="questionnaire-container">
    <router-outlet></router-outlet>
  </div>
</div>
