<div class="thank-you-container">
  <div class="thank-you-content">
    <h1 class="title">Bedankt!</h1>
    <p class="message">
      Nadat uw aanvraag is beoordeeld, neemt een installateur binnenkort contact
      met u op.
    </p>
    <img src="assets/images/hands.png" alt="High Five" class="image" />
  </div>
</div>
