import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PublicDropdownsService } from '../../services/public-dropdowns.service';
import { QuestionnaireWizardService } from '../../services/questionnaire-wizard.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-heatpomp-information-step',
  templateUrl: './heat-pomp-information.component.html',
  styleUrls: ['./heat-pomp-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeatPompInformationComponent implements OnInit {
  public heatPompInformationForm: FormGroup;
  public yearOfConstructionOptions: { label: string; value: string }[] = [];
  public deliverySystemOptions: { label: string; value: string }[] = [];
  public livingSpaceOptions = [];
  public installationDateOptions = [];
  public roomTypeOptions = [];

  public tooltipContent = `
    <div class="q-tooltip__title">
      Waar kan ik mijn verbruiksgegevens voor energie opvragen?
    </div>
    <div class="q-tooltip__content">
      Er zijn twee manieren om de verbruiksgegevens van jouw energie op te
      vragen. Ten eerste krijg je inzicht in het energieverbruik van jouw
      huishouden door de jaarlijkse afrekening van jouw energieleverancier te
      bekijken. Hier staan altijd het gas- en elektriciteitsverbruik van jouw
      woning op vermeld voor het afgelopen jaar. Wil je tussentijds inzicht in
      jouw verbruiksgegevens en wil je weten wat je op dit moment verbruikt, dan
      kun je deze gegevens opvragen door contact op te nemen met de helpdesk van
      jouw energieleverancier.
    </div>
  `
    .replace(/\n/g, '')
    .trim();

  constructor(
    private _questionnaireWizardSvc: QuestionnaireWizardService,
    private publicDropdownsService: PublicDropdownsService
  ) {}

  public ngOnInit(): void {
    this._questionnaireWizardSvc.activeStep = 3;
    this._initOptions();
    this._initHeatPompForm();
  }

  private _initHeatPompForm(): void {
    this.heatPompInformationForm =
      this._questionnaireWizardSvc.heatPompInformationForm;
  }

  public back(): void {
    this._questionnaireWizardSvc.goBack('step-2', 2);
  }

  public next(): void {
    this._questionnaireWizardSvc.goTo('contact', 4);
  }

  private _initOptions(): void {
    this.yearOfConstructionOptions =
      this.publicDropdownsService.getYearOfConstructionOptions();
    this.deliverySystemOptions =
      this.publicDropdownsService.getDeliverySystemOptions();

    this.livingSpaceOptions = [
      { label: 'Minder dan 100 m2', value: '100_or_less' },
      { label: '100 - 150 m2', value: '100_150' },
      { label: '150 - 200 m2', value: '150_200' },
      { label: '200 - 250 m2', value: '200_250' },
      { label: 'Meer dan 250 m2', value: '250_or_more' },
    ];
    this.installationDateOptions = [
      { label: '0-3 maanden', value: '0_3' },
      { label: '3-6 maanden', value: '3_6' },
      { label: '6-9 maanden', value: '6_9' },
      { label: '9-12 maanden', value: '9_12' },
      {
        label: 'Later dan 12 maanden',
        value: '12_later',
      },
    ];
    this.roomTypeOptions = [
      { label: 'Slaapkamer', value: 'bedroom' },
      { label: 'Huiskamer', value: 'living-room' },
      { label: 'Meerdere kamers', value: 'multiple-rooms' },
      { label: 'Kantoor', value: 'office' },
      { label: 'Overig', value: 'other' },
      { label: 'Winkel', value: 'store' },
    ];
  }
}
