import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  ErrorMessageInterceptor,
  PermissionGuard,
  TranslationLoader,
} from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgxsModule } from '@ngxs/store';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { InstallerCategoryWidgetComponent } from '@shared/dumb-components/installer-category-widget/installer-category-widget.component';
import { InstallerOverallRatingWidgetComponent } from '@shared/dumb-components/installer-overall-rating-widget/installer-overall-rating-widget.component';
import { SharedModule } from '@shared/shared.module';
import { LeadCommentsWidgetComponent } from '@shared/dumb-components/lead-comments-widget/lead-comments-widget.component';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { UrlSerializer } from '@angular/router';
import CustomUrlSerializer from '@core/classes/custom-url-serializer.class';
import { InstallerQuotesSentComponent } from '@shared/dumb-components/installer-quotes-sent/installer-quotes-sent.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FormlyTypesModule } from '@shared/modules/formly-types/formly-types.module';
import { InstallerCategoryDropdownComponent } from '@shared/modules/formly-types/types/installer-category-dropdown/installer-category-dropdown.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SentryErrorHandler } from '@core/errorHandlers/sentry.errorHandler';
import * as Sentry from '@sentry/browser';
import { environment } from '@environments/environment';
import { UserAgreementModule } from './features/user-agreement/user-agreement.module';
import { UploadLeadsComponent } from './features/leads/components/upload-leads/upload-leads.component';
import { PollingHandlerService } from './features/leads/services/polling-handler.service';
import { EndpointAction } from '@core/actions/endpoint.action';
import {
  EXPORT_POLLING,
  UPLOAD_POLLING,
} from '@core/provider/polling.provider';
import { UploadInstallersComponent } from './features/installers/components/upload-installers/upload-installers.component';
import { QuestionnaireModule } from './public/questionnaire/questionnaire.module';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryUrl,
  });
}

export function errorHandlerFactor() {
  if (environment.production) {
    return new SentryErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CoreModule,
    SharedModule,
    QuestionnaireModule,
    FormlyTypesModule,
    CapturumToastModule,
    CapturumBuilderCoreModule.forRoot({
      baseUrl: 'builders',
      actions: {
        endpoint: EndpointAction,
      },
      components: {
        list_leads_import_leads: UploadLeadsComponent,
        list_installers_import_installers: UploadInstallersComponent,
      },
    }),
    CapturumListRendererModule.forRoot({
      widgets: {
        'installer-category-widget': InstallerCategoryWidgetComponent,
        'installer-overall-rating-widget':
          InstallerOverallRatingWidgetComponent,
        'lead-comments-widget': LeadCommentsWidgetComponent,
        'installer-quotes-sent': InstallerQuotesSentComponent,
      },
    }),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: '',
      types: [
        {
          name: 'installercategorydropdown',
          component: InstallerCategoryDropdownComponent,
        },
      ],
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    NgxsModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    UserAgreementModule,
  ],
  providers: [
    {
      provide: UPLOAD_POLLING,
      useClass: PollingHandlerService,
    },
    {
      provide: EXPORT_POLLING,
      useClass: PollingHandlerService,
    },
    ConfirmationService,
    EndpointAction,
    PermissionGuard,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useFactory: errorHandlerFactor },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
