import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuestionnaireWizardService } from '../../services/questionnaire-wizard.service';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinalStepComponent implements OnInit {
  constructor(private questionnaireWizardSvc: QuestionnaireWizardService) {}

  public ngOnInit(): void {
    this.questionnaireWizardSvc.activeStep =
      this.questionnaireWizardSvc.totalSteps;
  }
}
