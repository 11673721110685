import { Component, ViewEncapsulation } from '@angular/core';
import { QuestionnaireWizardService } from '../../services/questionnaire-wizard.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent {
  constructor(private questionnaireWizardSvc: QuestionnaireWizardService) {}

  public get progress(): number {
    return this.questionnaireWizardSvc.progress;
  }

  public get currentStep(): number {
    return this.questionnaireWizardSvc.activeStep;
  }

  public get totalSteps(): number {
    return this.questionnaireWizardSvc.totalSteps;
  }
}
