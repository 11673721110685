import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loginRoutes } from '@capturum/login';
import {
  BaseDataResolver,
  IndexedDbGuard,
  ModuleResolver,
  PermissionGuard,
  PublicTranslationResolver,
  SettingResolver,
  TranslationResolver,
} from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from '@shared/modules/layout/layouts/basic-layout/basic-layout.component';
import { AppRoutes } from '@core/enums/routes.enum';
import { ProposalStatusLeadComponent } from './features/leads/components/proposal-status-lead/proposal-status-lead.component';
import { UserAgreementComponent } from './features/user-agreement/components/user-agreement/user-agreement.component';
import { UserAgreementGuard } from './features/user-agreement/guards/user-agreement.guard';
import { UserAgreementHasAgreedGuard } from './features/user-agreement/guards/user-agreement-has-agreed.guard';
import { LoginLayoutComponent } from '@shared/modules/layout/components/login-layout/login-layout.component';
import { LgPublicTranslationResolver } from '@core/resolvers/lg-public-translation.resolver';
import { UserTypeGuard } from '@core/guards/user-type.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard, PermissionGuard],
    resolve: {
      translations: TranslationResolver,
      baseData: BaseDataResolver,
      settings: SettingResolver,
      modules: ModuleResolver,
    },
    children: [
      {
        path: AppRoutes.userAgreement,
        canActivate: [UserAgreementHasAgreedGuard],
        component: UserAgreementComponent,
      },
      {
        path: '',
        component: BasicLayoutComponent,
        canActivate: [UserAgreementGuard],
        children: [
          {
            path: '',
            redirectTo: AppRoutes.dashboard,
            pathMatch: 'full',
          },
          {
            path: AppRoutes.dashboard,
            canActivate: [UserTypeGuard],
            loadChildren: () => {
              return import('./features/dashboard/dashboard.module').then(
                (m) => {
                  return m.DashboardModule;
                }
              );
            },
          },
          {
            path: AppRoutes.distributor,
            canActivate: [NgxPermissionsGuard],
            loadChildren: () => {
              return import('./features/distributors/distributors.module').then(
                (m) => {
                  return m.DistributorsModule;
                }
              );
            },
            data: {
              permissions: {
                only: 'distributor.manage.tenant',
                redirectTo: AppRoutes.dashboard,
              },
            },
          },
          {
            path: AppRoutes.installer,
            canActivate: [NgxPermissionsGuard],
            loadChildren: () => {
              return import('./features/installers/installers.module').then(
                (m) => {
                  return m.InstallersModule;
                }
              );
            },
            data: {
              permissions: {
                only: 'installer.manage.tenant',
                redirectTo: AppRoutes.dashboard,
              },
            },
          },
          {
            path: AppRoutes.lead,
            loadChildren: () => {
              return import('./features/leads/leads.module').then((m) => {
                return m.LeadsModule;
              });
            },
          },
          {
            path: AppRoutes.translation,
            loadChildren: () => {
              return import('./features/translation/translation.module').then(
                (m) => {
                  return m.TranslationModule;
                }
              );
            },
          },
          {
            path: AppRoutes.products,
            loadChildren: () => {
              return import('./features/product/products.module').then((m) => {
                return m.ProductsModule;
              });
            },
          },
          {
            path: AppRoutes.user,
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
          },
          {
            path: AppRoutes.baseData,
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then(
                (m) => {
                  return m.BaseDataModule;
                }
              );
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'base-data-value.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.config,
            loadChildren: () => {
              return import('./features/config/config.module').then((m) => {
                return m.ConfigModule;
              });
            },
          },
          {
            path: AppRoutes.builders.path,
            loadChildren: () => {
              return import('@capturum/builders/pages').then((m) => {
                return m.CapturumBuilderPagesModule;
              });
            },
          },
        ],
      },
    ],
  },
  {
    ...loginRoutes[0],
    component: LoginLayoutComponent,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: LgPublicTranslationResolver,
    },
    children: [...loginRoutes[0].children],
  },
  {
    path: '',
    loadChildren: () => {
      return import('./public/questionnaire/questionnaire.module').then((m) => {
        return m.QuestionnaireModule;
      });
    },
  },
  {
    path: AppRoutes.review,
    loadChildren: () => {
      return import('./features/review/review.module').then((m) => {
        return m.ReviewModule;
      });
    },
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
  },
  {
    path: `${AppRoutes.proposedLead}/:token/:status`,
    component: ProposalStatusLeadComponent,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
  },
  {
    path: '**',
    redirectTo: AppRoutes.dashboard,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
