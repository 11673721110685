import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuestionnaireWizardService } from '../../services/questionnaire-wizard.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactInfoComponent implements OnInit {
  public privateBusinessOptions = [
    { label: 'Privaat', value: 'private' },
    { label: 'Bedrijf', value: 'business' },
  ];

  public contactForm: FormGroup;

  constructor(private questionnaireWizardSvc: QuestionnaireWizardService) {}

  public ngOnInit(): void {
    this._initContactForm();
  }

  private _initContactForm(): void {
    this.contactForm = this.questionnaireWizardSvc.contactForm;
  }

  public back(): void {
    const hostName = location.hostname;

    if (hostName.includes('airco')) {
      this.questionnaireWizardSvc.goBack('step-1', 1);
    } else {
      this.questionnaireWizardSvc.goBack('step-3', 3);
    }
  }

  public submit(): void {
    this.questionnaireWizardSvc.submitQuestionnaire();
  }
}
