import { Injectable } from '@angular/core';
import { AuthConfig, AuthService as CompleteAuthService } from '@capturum/auth';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '@features/user/models/user.model';
import { UserRole } from '@features/user/enums/user-role.enum';
import { ConfirmationService } from '@core/services/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { map, Observable } from 'rxjs';
import { BaseDataService } from './base-data.service';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { Country } from '@features/leads/enums/country.emum';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CompleteAuthService {
  constructor(
    config: AuthConfig,
    http: HttpClient,
    protected readonly router: Router,
    protected readonly translateService: TranslateService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly toastService: ToastService,
    private readonly baseDataService: BaseDataService
  ) {
    super(config, http);
  }

  public getUser(): User {
    return super.getUser() as User;
  }

  public getUserRole(): UserRole {
    if (this.isAdministrator()) {
      return UserRole.ADMINISTRATOR;
    } else if (this.isCountryAdministrator()) {
      return UserRole.COUNTRY_ADMINISTRATOR;
    } else if (this.isDistributor()) {
      return UserRole.DISTRIBUTOR;
    } else if (this.isInstaller()) {
      return UserRole.INSTALLER;
    } else if (this.isTelemarketing()) {
      return UserRole.TELEMARKETING;
    } else {
      return null;
    }
  }

  public isRole(roleKey: UserRole | string): boolean {
    return this.getUserRole() === roleKey;
  }

  public isOneOfRoles(roleKeys: (UserRole | string)[]): boolean {
    return roleKeys.some((key) => {
      return this.isRole(key);
    });
  }

  public isAdministrator(): boolean {
    const currentlyLoggedInUser = this.getUser();

    return currentlyLoggedInUser?.currentRole?.key === UserRole.ADMINISTRATOR;
  }

  public isCountryAdministrator(): boolean {
    const currentlyLoggedInUser = this.getUser();

    return (
      currentlyLoggedInUser?.currentRole?.key === UserRole.COUNTRY_ADMINISTRATOR
    );
  }

  public isInstaller(): boolean {
    const currentlyLoggedInUser = this.getUser();

    return currentlyLoggedInUser?.currentRole?.key === UserRole.INSTALLER;
  }

  public isDistributor(): boolean {
    const currentlyLoggedInUser = this.getUser();

    return currentlyLoggedInUser?.currentRole?.key === UserRole.DISTRIBUTOR;
  }

  public isTelemarketing(): boolean {
    const currentlyLoggedInUser = this.getUser();

    return currentlyLoggedInUser?.currentRole?.key === UserRole.TELEMARKETING;
  }

  public isCountryBenelux(): Observable<boolean> {
    return this.baseDataService
      .findByValue(BaseDataKey.COUNTRY, Country.BENELUX)
      .pipe(
        map(
          (country) =>
            country && this.getUser().country_base_data_value_id === country.id
        )
      );
  }

  public handleSuccessfulLogin(response: any): void {
    if (response.method) {
      this.router.navigate(['/', 'auth', 'two-factor'], {
        queryParams: {
          method: response.method,
          userId: response.user_id,
        },
      });
    }

    const user = response.user;

    if (!user?.installer_active && user.agreed_at) {
      setTimeout(() => {
        this.confirmationService.confirm({
          header: this.translateService.instant('builders.confirmation.title'),
          message: this.translateService.instant(
            'LeadPortal.user.confirm-activation.message'
          ),
          accept: () => {
            this.http
              .post(
                `${this.config.baseUrl}/user/${user.id}/activate`,
                {},
                { headers: this.getHeaders() }
              )
              .subscribe({
                next: () => {
                  this.toastService.success(
                    this.translateService.instant('toast.success.title'),
                    this.translateService.instant(
                      'LeadPortal.user.activation.toast.message'
                    )
                  );
                },
              });
          },
        });
      }, 2000);
    }
  }
}
