import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { Quotes } from '../models/quotes.model';
import { LeadStatusesOverview } from '../models/lead-statuses-overview.model';
import { InstallerStatus } from '../models/installer-status.model';
import { UserRole } from '@features/user/enums/user-role.enum';
import { DynamicDashboardItem } from '@features/dashboard/models/dynamic-dashboard-item.model';
import { LeadsStatusesChartComponent } from '@features/dashboard/components/leads-statuses-chart/leads-statuses-chart.component';
import { InstallerStatusComponent } from '@features/dashboard/components/installer-status/installer-status.component';
import { AuthService } from '@core/services/auth.service';
import { BestPerformingInstallersComponent } from '../components/best-performing-installers/best-performing-installers.component';
import { MostSoldModelsComponent } from '../components/most-sold-models/most-sold-models.component';
import { LeadsToBeCheckedComponent } from '../components/leads-to-be-checked/leads-to-be-checked.component';
import { UtmChartComponent } from '../components/utm-chart/utm-chart.component';
import { User } from '../../user/models/user.model';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { Country } from '../../leads/enums/country.emum';
import { BaseDataService } from '@core/services/base-data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ApiService<any> {
  protected endpoint = 'dashboard';

  /**
   * This contains all possible dashboard items
   *
   * @private
   */
  private dashboardItems: Record<string, DynamicDashboardItem> = {
    leadStatusChart: {
      styleConfig: {
        administrator: 'col-12 col-lg-4',
        country_administrator: 'col-12 col-lg-4',
        distributor: 'col-12 col-lg-4',
        installer: 'col-12 col-lg-4',
      },
      component: LeadsStatusesChartComponent,
    },
    installerStatus: {
      styleConfig: {
        administrator: 'col-12 col-lg-7',
        country_administrator: 'col-12 col-lg-7',
        installer: 'col-12 col-lg-4',
      },
      component: InstallerStatusComponent,
    },
    mostSoldModels: {
      styleConfig: {
        administrator: 'col-12 col-lg-4',
        country_administrator: 'col-12 col-lg-4',
        distributor: 'col-12 col-lg-4',
        installer: 'col-12 col-lg-8',
      },
      component: MostSoldModelsComponent,
    },
    bestPerformingInstallers: {
      styleConfig: {
        administrator: 'col-12 col-lg-4',
        country_administrator: 'col-12 col-lg-4',
        distributor: 'col-12 col-lg-4',
      },
      component: BestPerformingInstallersComponent,
    },
    leadsToBeChecked: {
      styleConfig: {
        administrator: 'col-12 col-lg-8',
        country_administrator: 'col-12 col-lg-8',
        distributor: 'col-12 col-lg-8',
      },
      component: LeadsToBeCheckedComponent,
    },
    utmAnswerChart: {
      styleConfig: {
        administrator: 'col-12 col-lg-4',
        country_administrator: 'col-12 col-lg-4',
        distributor: 'col-12 col-lg-4',
        installer: 'col-12 col-lg-8',
      },
      component: UtmChartComponent,
    },
  };

  /**
   * This contains the configuration of dashboard items per role type.
   *
   * @private
   */
  private dashboardItemsByRole: Record<string, DynamicDashboardItem[]> = {
    [UserRole.COUNTRY_ADMINISTRATOR]: [
      this.dashboardItems.leadsToBeChecked,
      this.dashboardItems.leadStatusChart,
      this.dashboardItems.mostSoldModels,
      this.dashboardItems.bestPerformingInstallers,
      this.dashboardItems.utmAnswerChart,
    ],
    [UserRole.INSTALLER]: [
      this.dashboardItems.mostSoldModels,
      this.dashboardItems.leadStatusChart,
      this.dashboardItems.installerStatus,
    ],
    [UserRole.DISTRIBUTOR]: [
      this.dashboardItems.leadsToBeChecked,
      this.dashboardItems.leadStatusChart,
      this.dashboardItems.mostSoldModels,
      this.dashboardItems.bestPerformingInstallers,
      this.dashboardItems.utmAnswerChart,
    ],
    [UserRole.ADMINISTRATOR]: [
      this.dashboardItems.leadsToBeChecked,
      this.dashboardItems.leadStatusChart,
      this.dashboardItems.mostSoldModels,
      this.dashboardItems.bestPerformingInstallers,
      this.dashboardItems.utmAnswerChart,
    ],
  };

  constructor(
    public apiHttp: ApiHttpService,
    private authService: AuthService,
    private readonly baseDataService: BaseDataService
  ) {
    super(apiHttp);
  }

  public getQuotes(options: ListOptions): Observable<Quotes> {
    let url = `/${this.endpoint}/lead`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getLeadStatusData(
    options?: ListOptions
  ): Observable<LeadStatusesOverview> {
    const url = `/${this.endpoint}/lead-status${ApiService.generateQuery(
      options
    )}`;

    return this.apiHttp.get(url);
  }

  public getMostSoldModels(options: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/most-sold-models`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getBestPerformingInstallers(options: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/best-performing-installers`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getLeadsToBeChecked(options: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/leads-to-check`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getLongestResponseTime(options: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/longest-response-time`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getInstallerDetails(
    options?: ListOptions
  ): Observable<InstallerStatus> {
    let url = `/${this.endpoint}/installer`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getDashboardConfigByRole(): Observable<DynamicDashboardItem[]> {
    const currentUser: User = this.authService.getUser();
    const userRole = this.authService.getUserRole();
    let dashboardConfig = this.dashboardItemsByRole[userRole];

    return this.baseDataService
      .findByValue(BaseDataKey.COUNTRY, Country.BENELUX)
      .pipe(
        map((country) => {
          if (
            country &&
            currentUser.country_base_data_value_id !== country.id
          ) {
            dashboardConfig = dashboardConfig.filter((item) => {
              return item !== this.dashboardItems.utmAnswerChart;
            });
          }

          return dashboardConfig;
        })
      );
  }

  public getUtmAnswerData(options?: ListOptions): Observable<any> {
    const url = `/${this.endpoint}/lead-utm${ApiService.generateQuery(
      options
    )}`;

    return this.apiHttp.get(url);
  }

  public getUtms(options?: ListOptions): Observable<any> {
    const url = `/${this.endpoint}/utm-list${ApiService.generateQuery(
      options
    )}`;

    return this.apiHttp.get(url);
  }
}
