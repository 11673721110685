import { Injectable } from '@angular/core';
import {
  Confirmation,
  ConfirmationService as PrimengConfirmationService,
} from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private confirmationService: PrimengConfirmationService) {}

  public confirm(confirmation: Confirmation): void {
    this.confirmationService.confirm(confirmation);
  }
}
