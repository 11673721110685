<div class="step-container">
  <div class="step-content">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-md-4">
          <div class="image-section">
            <img src="./assets/images/img.png" alt="Contact Information" />
          </div>
        </div>

        <div class="col-md-8">
          <form [formGroup]="contactForm" class="form-section">
            <div class="text-section">
              <h1 class="step-title">Laat ons uw contactgegevens weten</h1>
              <p class="step-description">
                Vul uw contactgegevens in en een erkend LG installateur neemt
                contact met u op.
              </p>
            </div>

            <div class="mobile-image">
              <img
                src="./assets/images/contact-mobile.png"
                alt="Contact Information"
              />
            </div>

            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-md-5 control-label">
                  <div>Voornaam en achternaam *</div>
                </div>

                <div class="col-md-7 control-field">
                  <input
                    id="name"
                    type="text"
                    class="form-input"
                    formControlName="name"
                    placeholder="Voornaam en achternaam *"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 control-label">
                  <div>Adres *</div>
                </div>

                <div class="col-md-7 control-field">
                  <input
                    id="address"
                    type="text"
                    class="form-input"
                    formControlName="address"
                    placeholder="Adres *"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 control-label">
                  <div>Postcode *</div>
                </div>

                <div class="col-md-7 control-field">
                  <input
                    id="postalCode"
                    type="text"
                    class="form-input"
                    formControlName="zip_code"
                    placeholder="Postcode *"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 control-label">
                  <div>Plaats *</div>
                </div>

                <div class="col-md-7 control-field">
                  <input
                    id="place"
                    type="text"
                    class="form-input"
                    formControlName="city"
                    placeholder="Plaats *"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 control-label">
                  <div>E-mailadres *</div>
                </div>

                <div class="col-md-7 control-field">
                  <input
                    id="email"
                    type="email"
                    class="form-input"
                    formControlName="email"
                    placeholder="E-mailadres *"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 control-label">
                  <div>Telefoonnummer *</div>
                </div>

                <div class="col-md-7 control-field">
                  <input
                    id="phone"
                    type="tel"
                    class="form-input"
                    formControlName="phone"
                    placeholder="Telefoonnummer *"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 control-label">
                  <div>Vraag en/of opmerking</div>
                </div>

                <div class="col-md-7 control-field">
                  <textarea
                    id="comments"
                    class="form-textarea"
                    formControlName="remark"
                    placeholder="Vraag en/of opmerking"
                  ></textarea>
                </div>
              </div>
            </div>

            <hr class="form-separator" />

            <div class="privacy-section">
              <label class="privacy-title">Privacybeleid *</label>
              <div class="consent-group">
                <input
                  type="checkbox"
                  id="privacyPolicy"
                  class="consent-checkbox"
                  formControlName="privacyPolicy"
                />
                <label for="privacyPolicy" class="consent-label">
                  Ik heb het privacybeleid gelezen en begrepen.
                  <a href="/assets/nl-privacy-policy.pdf" download>Lees meer</a>
                </label>
              </div>
            </div>

            <div class="button-container">
              <app-back-button (onClick)="back()"></app-back-button>

              <button
                class="next-button"
                [disabled]="contactForm.invalid"
                (click)="submit()"
              >
                Indienen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
