<div class="step-container">
  <div class="step-content">
    <div class="text-section">
      <h1 class="step-title">Eigendomsgegevens</h1>
      <p class="step-description">
        Laat ons weten wat uw voorkeuren zijn. Selecteer de opties die het beste
        bij uw situatie passen:
      </p>
    </div>

    <div class="container-fluid px-0">
      <ng-container [formGroup]="heatPompInformationForm">
        <div class="row">
          <div class="col-md-4 control-label">
            <div>Jaarlijks gasverbruik *</div>
          </div>

          <div class="col-md-8 control-field">
            <div class="input-container">
              <input
                id="gas-consumption"
                type="text"
                formControlName="gas_consumption"
                placeholder="0.00"
              />
              <span class="unit-label">/M3</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Jaarlijks stroomverbruik *</div>
          </div>

          <div class="col-md-8 control-field">
            <div class="input-container">
              <input
                id="power-consumption"
                type="text"
                formControlName="power_consumption"
                placeholder="0.00"
              />
              <span class="unit-label">/kWh</span>
            </div>
          </div>
        </div>

        <div class="row b-b">
          <div class="col-md-4 control-label">
            <div>Bouwjaar</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="yearOfConstructionOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="construction_year"
              [sortAlphabetically]="false"
            >
            </cap-dropdown>
          </div>
        </div>

        <div class="row b-b">
          <div class="col-md-4 control-label">
            <div>Bestaande Afgiftesysteem</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="deliverySystemOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="current_distribution_system"
              [sortAlphabetically]="false"
            >
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Woonoppervlakte *</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="livingSpaceOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="living_space"
              [sortAlphabetically]="false"
            >
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Gewenste installatiedatum</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="installationDateOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="preferred_installation_period"
              [sortAlphabetically]="false"
            >
            </cap-dropdown>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="button-container">
    <button class="back-button" (click)="back()">Terug</button>
    <button class="next-button" [disabled]="heatPompInformationForm.invalid" (click)="next()">Volgende</button>
  </div>
</div>
