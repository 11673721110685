import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { FinalStepComponent } from './components/final-step/final-step.component';
import { GeneralInformationStepComponent } from './components/general-information/general-information-step.component';
import { ProjectInformationComponent } from './components/project-information/project-information.component';
import { HeatPompInformationComponent } from './components/heat-pomp-information/heat-pomp-information.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';

const routes: Routes = [
  {
    path: '',
    component: QuestionnaireComponent,
    children: [
      { path: 'step-1', component: GeneralInformationStepComponent },
      { path: 'step-2', component: ProjectInformationComponent },
      { path: 'step-3', component: HeatPompInformationComponent },
      { path: 'contact', component: ContactInfoComponent },
      { path: 'final', component: FinalStepComponent },
      { path: '**', redirectTo: 'step-1', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionnaireRoutingModule {}
