<div class="page-footer">
  <div class="brand-name">LG Lead Platform</div>

  <a
    [href]="'/assets/' + currentLang + '-terms-of-use.pdf'"
    [attr.download]="currentLang === 'de' ? '' : null"
    target="_blank"
  >
    {{ 'terms-of-use' | translate }}
  </a>

  <a
    *ngIf="currentLang === 'nl' || currentLang === 'en' || currentLang === 'fr'"
    href="/assets/{{ currentLang }}-{{
      currentEnv === 'Airconditioning' ? 'ac' : 'hp'
    }}-manual.pdf"
    target="_blank"
    >{{ 'manual' | translate }}</a
  >

  <a
    [href]="
      currentLang === 'de'
        ? 'https://www.lg.com/de/business/privacy/'
        : '/assets/' + currentLang + '-privacy-policy.pdf'
    "
    target="_blank"
  >
    {{ 'privacy-policy' | translate }}
  </a>

  <a href="{{ currentLang | instructionalVideoLink }}" target="_blank">{{
    'instructional-video' | translate
  }}</a>
</div>
