import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { Observable } from 'rxjs';

@Injectable()
export class QuestionnaireService {
  private endpoint = '/lead/questionnaire';

  constructor(private apiHttp: ApiHttpService) {}

  public submitQuestionnaire(data: any): Observable<any> {
    return this.apiHttp.post(this.endpoint, data);
  }
}
