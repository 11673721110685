import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appStarRating]',
})
export class StarRatingDirective implements OnInit, OnChanges {
  @Input() public rating: number;
  @Input() public currentStarIndex;
  @Input() public readonly;

  public showHalfRating: boolean;
  public fullStarClass = ['fas', 'fa-star'];
  public halfStarClass = ['fas', 'fa-star', 'half'];
  public emptyStarClass = ['fal', 'fa-star'];

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.setIconClass();
  }

  public ngOnChanges(): void {
    this.setIconClass();
  }

  public setIconClass(): void {
    this.elementRef.nativeElement.className = '';
    this.rating = +this.rating;

    this.showHalfRating = this.shouldShowHalfRating();

    if (this.rating < this.currentStarIndex && !this.readonly) {
      this.elementRef.nativeElement.classList.add('fal', 'fa-star');
    } else if (this.rating >= this.currentStarIndex && !this.showHalfRating) {
      this.elementRef.nativeElement.classList.add('fas', 'fa-star');
    } else if (this.showHalfRating) {
      this.elementRef.nativeElement.classList.add('fas', 'fa-star', 'half');
    }
  }

  public shouldShowHalfRating(): boolean {
    if (Math.trunc(this.rating + 1) === this.currentStarIndex) {
      const integerValue = Math.trunc(this.rating);
      const minRange = parseFloat(`${integerValue}.25`);
      const maxRange = parseFloat(`${integerValue}.75`);

      if (this.rating > maxRange) {
        this.rating = Math.round(this.rating);

        return false;
      }

      if (this.rating >= minRange && this.rating <= maxRange) {
        return true;
      }
    }
  }
}
