import { FilterMatchMode } from '@capturum/ui/api';
import {
  CapturumInfoTableComponent,
  InfoTableColumn,
} from '@capturum/ui/info-table';
import { Component, Injector, OnDestroy, ViewChild } from '@angular/core';
import { BaseListComponent } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { FilterMetadata, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DateConverterUtil } from '@core/utils/date-converter.util';
import { BaseFilterService } from '@shared/modules/filters/services/base-filter.service';
import { FilterConfig } from '@shared/modules/filters/models/filter-config.model';
import { DateFormats } from '@core/configs/app-date.config';

@Component({
  template: '',
})
// tslint:disable-next-line:component-class-suffix
export class BaseListClass<Entity>
  extends BaseListComponent<Entity>
  implements OnDestroy
{
  @ViewChild(CapturumInfoTableComponent, { static: false })
  public infoTable: CapturumInfoTableComponent;

  public perPageOptions: SelectItem[] = [0, 15, 25, 50].map((value) => {
    return {
      label: value.toString(),
      value,
    };
  });

  public perPage: number = this.perPageOptions[0].value;
  public tableColumns: InfoTableColumn[];
  public filterConfig: FilterConfig[];
  public removeDataWrappers = false;

  protected filterService: BaseFilterService;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public injector: Injector) {
    super(injector, injector.get(TranslateService));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public filterGlobal(value: string): void {
    this.loadTableData({ ...this.lazyLoadEvent, globalFilter: [value] });
  }

  public filter(event: { value: any; field: string; matchMode: string }): void {
    this.infoTable.filterTable(
      event.value,
      event.field,
      event.matchMode as FilterMatchMode
    );
  }

  public get activeFilters(): Record<string, FilterMetadata> {
    let activeFilters = {};

    if (this.infoTable && this.infoTable.activeFilters) {
      activeFilters = this.infoTable.activeFilters;

      for (const key in activeFilters) {
        if (activeFilters.hasOwnProperty(key)) {
          const value = (activeFilters[key] as FilterMetadata).value;

          if (Date.parse(value) && !(value instanceof Date)) {
            activeFilters[key] = {
              ...activeFilters[key],
              value: new Date(value),
            };
          }
        }
      }
    }

    return activeFilters;
  }

  protected parseFilter(
    field: string,
    filter: { value: any; matchMode?: string }
  ): any {
    const apiFilter = super.parseFilter(field, filter);

    let value = apiFilter.value;

    if (Array.isArray(value) && DateConverterUtil.isDate(value[0])) {
      const isDateRange = value.every((val) => {
        return (
          moment(val, ['DD-MM-YYYY', 'YYYY-MM-DD'], true).isValid() ||
          moment.isDate(val)
        );
      });

      if (isDateRange) {
        const startDate = moment(value[0])
          .startOf('day')
          .format(DateFormats.sendFormatWithTimeAndDashes);
        const endDate = moment(value[1])
          .endOf('day')
          .format(DateFormats.sendFormatWithTimeAndDashes);

        value = [startDate, endDate];
      }
    }

    return {
      field,
      value,
      operator: apiFilter.operator,
    };
  }
}
